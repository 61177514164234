import React from "react"
import ReactHtmlParser from 'react-html-parser'

const Header = (props) => {
  return (
      <div className={`header ${props.style.color}`} data-sal="fade" data-sal-easing="ease" data-sal-duration="600">
        <h1 className="header__title">{ReactHtmlParser(props.content.title)}</h1>
        <p className="header__subtitle">{props.content.text}</p>
      </div>
    )
}

export default Header
