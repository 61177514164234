import React from "react"
import Particles from 'react-particles-js';


const BackgroundShape = (props) => {
  return (
    <div className={`shape-container ${props.style.shape} ${props.style.color}`}>
    <div className="desktop-only">
      {props.style.particles ?

      <Particles
      params={{
          particles: {
            move: {
              speed: 1
            },
            size: {
              value: 5,
              random: true
            },
            line_linked: {
              enable: false
            },
            opacity: {
              value: 0.3,
              random: false,
              anim: {
                enable: true,
                speed: 0.2,
                opacity_min: 0.1,
                sync: false
              }
            }
          },
        }}  />
      : ""}
    </div>
  </div>

  )
}

export default BackgroundShape
